<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Editar Siniestro </ion-title>
    <ion-form>
      <ion-label style="float: left; ">Colaborador</ion-label><br>
      <ion-select v-model="siniestro.ID_COLABORADOR" placeholder="Seleccione el colaborador">
        <ion-select-option v-for="colaborador in colaboradores" :key="colaborador" :value="colaborador.UID">{{colaborador.USER}} </ion-select-option>
      </ion-select>
      
      <ion-label style="float: left; ">Nombre</ion-label><br>
      <ion-input type="text" v-model="siniestro.NOMBRE"  placeholder="Ingrese el nombre" />
      
      <ion-label style="float: left; ">Telefono</ion-label><br>
      <ion-input type="text" v-model="siniestro.TELEFONO"  placeholder="Ingrese el telefono" />
      
      <ion-label style="float: left; ">Movil</ion-label><br>
      <ion-input type="text" v-model="siniestro.MOVIL"  placeholder="Ingrese el movil" />
      
      <ion-label style="float: left; ">Aseguradora</ion-label><br>
      <ion-input type="text" v-model="siniestro.ASEGURADORA"  placeholder="Ingrese la aseguradora" />

      <ion-label style="float: left; ">Poliza</ion-label><br>
      <ion-input type="text" v-model="siniestro.POLIZA"  placeholder="Ingrese el numero de poliza" />

      <ion-label style="float: left; ">Matricula</ion-label><br>
      <ion-input type="text" v-model="siniestro.MATRICULA"  placeholder="Ingrese el numero de matricula" />

      <ion-label style="float: left; ">Fecha del Sucedido</ion-label><br>
      <ion-input type="date" v-model="siniestro.FECHA_SUC"  placeholder="Ingrese la fecha del sucedido" />

      <ion-label style="float: left; ">Fecha de la apertura</ion-label><br>
      <ion-input type="date" v-model="siniestro.FECHA_APER"  placeholder="Ingrese la fecha del apertura" />

      <ion-label style="float: left; ">Fecha de cierre</ion-label><br>
      <ion-input type="date" v-model="siniestro.FECHA_CIERRE"  placeholder="Ingrese la fecha del cierre" />

      <ion-label style="float: left; ">Fecha de la ultima gestion</ion-label><br>
      <ion-input type="date" v-model="siniestro.FECHA_ULTGESTION"  placeholder="Ingrese la fecha de la ultima gestion" />

      <ion-label style="float: left; ">Culpabilidad</ion-label><br>
      <ion-input type="text" v-model="siniestro.CULPABILIDAD"  placeholder="Ingrese la Culpabilidad" />

      <ion-label style="float: left; ">Version del sucedido</ion-label><br>
      <ion-textarea type="text"  v-model="siniestro.VERSION_SUCEDIDO" placeholder="Ingrese version del sucedido" />

      <ion-label style="float: left; ">Direccion</ion-label><br>
      <ion-textarea type="text" v-model="siniestro.DIRECCION"  placeholder="Ingrese la direccion" />
      
      <ion-button color="dark" @click="editSiniesto">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput,IonSelect,IonTextarea } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  props: ['data_siniestro'],
  components :{IonInput,IonSelect,IonTextarea},
  setup() {
    return { close }
  },
  data(){
    return{ 
      siniestro : {
        ASEGURADORA: null,
        CULPABILIDAD:  null,
        DIRECCION:  null,
        ESTADO:  null,
        FECHA_APER:  null,
        FECHA_CIERRE:  null,
        FECHA_SUC:  null,
        FECHA_ULTGESTION:  null,
        ID_COLABORADOR:  null,
        ID_SINIESTRO:  null,
        MATRICULA:  null,
        MOVIL: null,
        NOMBRE:  null,
        POLIZA:  null,
        TELEFONO:  null,
        VERSION_SUCEDIDO:  null,
      }
    }
  },
  mounted(){
    this.getColaboradores()
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
    getColaboradores(){
      axios.get('/api/colaboradores?all=true')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
        this.siniestro = this.data_siniestro
      })
      .catch(error => {
        console.log(error)
      })
    },
    async editSiniesto(){
       var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/siniestros/'+this.siniestro.ID_SINIESTRO,{
        id_colaborador : this.siniestro.ID_COLABORADOR,
        nombre : this.siniestro.ASEGURADORA,
        telefono:  this.siniestro.TELEFONO,
        aseguradora:  this.siniestro.ASEGURADORA,
        poliza: this.siniestro.POLIZA,
        matricula: this.siniestro.MATRICULA,
        movil: this.siniestro.MOVIL,
        fecha_suc : this.siniestro.FECHA_SUC,
        fecha_aper: this.siniestro.FECHA_APER,
        fecha_cierre : this.siniestro.FECHA_CIERRE,
        fecha_ultgestion: this.siniestro.FECHA_ULTGESTION,
        culpabilidad : this.siniestro.CULPABILIDAD,
        version_sucedido: this.siniestro.VERSION_SUCEDIDO,
        direccion : this.siniestro.DIRECCION,
        estado : 1
      })
      .then(data => {
        console.log(data)
        loading.dismiss()
        this.$toast.openToast("Edición de siniestro exitoso","success")
      }).catch(err => {
         loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
