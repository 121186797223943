<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar Nota </ion-title>
    <ion-form>
      
      <!--<ion-label style="float: left;">Fecha</ion-label><br>
      <ion-input type="date" v-model="fecha"  placeholder="Ingrese la fecha del sucedido" />-->

      <ion-label style="float: left;">Observaciones</ion-label><br>
      <ion-textarea type="text" v-model="observaciones"  placeholder="Ingrese las Observaciones" />
      
      <div style="display: flex;justify-content: center;">
        <ion-button color="dark" @click="file" style="width: 30%">
          Subir Archivo
        </ion-button>
      </div>
      
      <br>
      
      <input type="file" @change="getFile" style="display: none"  ref="file"  id="file-input" name="file-input" />
      
      <ion-button color="dark" @click="postNota">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonTextarea } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  props:['id_siniestro'],
  components : {IonTextarea},
  setup() {
    return { close }
  },
  data(){
    return{
      observaciones :null,
      fecha :null,
      archivo : null
    }
  },
  mounted(){
    this.getSiniestros()
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    file(){
      document.querySelector('#file-input').click()
    },
    getFile(){
      this.archivo = this.$refs.file.files[0]
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
    getSiniestros(){
      axios.get('/api/siniestros?all=true')
      .then(res => {
        this.siniestros = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    async postNota(){
      var loading = await toast.showLoading()

      await loading.present();

      var date = new Date().toISOString()
      var fecha = date.split('T')[0];
      
      var formData = new FormData()
      
      formData.append("observaciones",this.observaciones)
      formData.append("id_siniestro",this.id_siniestro)
      formData.append("archivo",this.archivo)
      formData.append("fecha",fecha)

      axios.post('/api/notas',formData)
      .then(data => {
         loading.dismiss()
        console.log(data)
      }).catch(err => {
         loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
