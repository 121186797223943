<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col></ion-col>
          <ion-col></ion-col>
          <ion-col>  
            <ion-button color="dark" style="width: 45px;" @click="openModalAdd">
              +
            </ion-button>
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
             <ion-title> {{name_siniestro}} </ion-title>
              <table class="table table-responsive-sm table-striped table-align-middle" style="display: inline-table;">
                <thead>
                  <tr>
                    <th>Nota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="nota in notas.data" :key="nota">
                    <td>{{nota?.OBSERVACIONES}}</td>
                    <td> 
                        <a :href="$baseURL+'notas/'+nota?.ARCHIVO"  target="_blank">Ver Archivo</a>
                    </td>
                    <!--<td>
                      <ion-button color="primary"  @click="openModalEdit(nota)">
                        Editar
                      </ion-button> 
                    </td>-->
                    
                  </tr>
                </tbody>
              </table>
              <nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                  <li  :class="{'page-item' : true , 'disabled' : !notas.prev_page_url }">
                    <a class="page-link"   @click="page(notas.prev_page_url)" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item"><a class="page-link">{{notas.current_page}}</a></li>
                  <li :class="{'page-item' : true , 'disabled' : !notas.next_page_url }">
                    <a class="page-link"  @click="page(notas.next_page_url)">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation } from '@ionic/vue';

import axios from 'axios'
import agregarNota from './agregarNota';
import editarNota from './editarNota';

export default defineComponent({
  name: 'FolderPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  data(){
    return{
      notas: [],
      id_siniestro: null,  
    }
  },
  mounted(){

    this.id_siniestro = this.$route.params.id_siniestro
    this.name_siniestro = this.$route.query.name_siniestro
    this.getNotas()
  },
  methods:{
    getNotas(){
      axios.get('/api/notas/'+this.id_siniestro+'/siniestro')
      .then(res => {
        console.log(res.data.data)
        this.notas = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.notas = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
     async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarNota,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {id_siniestro : this.id_siniestro}
        })
      
      modal.present();

      modal.onDidDismiss().then((data) => {
        this.getNotas()
        console.log(data)
      })

    },
    async openModalEdit(nota) {
    
      const modal = await modalController
        .create({
          component: editarNota,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {id_siniestro : nota.ID_SINIESTRO}
        })
      
      modal.present();
      
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getNotas()
      })
    },
    enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

</style>
